.character_search_container {
  position: relative;
}

.character_search_main_container {
  position: relative;
  margin: 5px 0;
}

.character_search_icon_character {
  position: absolute;
  left: 0.5em;
  top: 0;
  bottom: 0;
  height: 15pt;
  width: 15pt;
  margin: auto;
}

.character_search_input_text {
  font-size: var(--font-search-size);
  background-color: var(--color-input-search-background);
  color: var(--color-text);
  border: 1px solid var(--color-input-search-border);
  padding: 5px 1px 5px 2rem;
  border-radius: 5px;
  box-sizing: border-box;
}

.character_search_input_text::placeholder {
  font-size: var(--font-search-size);
  font-style: italic;
  vertical-align: middle;
}

.character_search_input_text:focus {
  background-color: var(--color-input-search-background-focus);
  outline: none;
}

.character_search_container_back {
  display: none;
}

.character_search_container_back > img {
  width: 26pt;
  height: 26pt;
}

.character_search_float_container {
  position: absolute;
  width: 100%;
  top: 55px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-input-search-background-focus);
  color: var(--color-text);
  border-radius: 5px;
  padding: 10px 10px;
  user-select: none;
  z-index: 50;
  box-sizing: border-box;
}

.character_search_float_container_list {
  overflow-x: hidden;
  max-height: 20rem;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.character_search_float_item_origin_image {
  height: 1rem;
  float: right;
  margin-right: 0.2rem;
}

.character_search_float_container_list::-webkit-scrollbar {
  display: none;
}

.character_search_float_line {
  width: 100%;
  border-bottom: solid 1px var(--color-input-dropdown-separator);
  margin: 5px 0;
  box-shadow: 1px 2px 5px 2px var(--color-shadow);
}

.character_search_input_selected {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 2em;
  margin: auto;
}

.character_search_input_selected_item {
  display: flex;
  font-size: var(--font-search-size);
  border-radius: 5px;
  border: 1px solid #999;
  margin: 3px 1px;
  padding: 3px 2px;
  color: #fff;
}

.character_search_input_token_x {
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  .character_search_main_container_full {
    width: 100% !important;
  }

  .character_search_input_text {
    width: calc(100% - 0.4rem);
  }

  .character_search_float_container_item {
    font-size: 1.4rem;
    padding: 0.2rem 0;
  }

  .character_search_float_item_origin_image {
    margin-top: 0.1rem;
    height: 1.2rem;
  }

  .character_search_input_text:focus {
    width: 100%;
  }

  .character_search_input_selected {
    display: none;
  }

  .character_search_float_container {
    position: fixed;
    top: calc(55px + 6rem);
    left: 0;
    right: 0;
    padding: 0 0.4rem;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 55px);
    margin: auto;
  }

  .character_search_float_container_list {
    max-height: unset;
  }

  .character_search_container_back {
    display: block;
    position: fixed;
    top: 8px;
    left: 5px;
    background-color: rgb(17 17 17);
  }
}