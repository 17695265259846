.submit_button {
  outline: none;
  background: none;
  border: none;
  box-shadow: 0 0 1px 0px;
  border-radius: 5px;
}

.submit_button:hover {
  box-shadow: 0 0 2px 0px;
}

.submit_button_check {
  background: url(./check.svg) no-repeat center;
}

.submit_button_x {
  background: url(./x.svg) no-repeat center;
}