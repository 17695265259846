.media_item_container {
  display: flex;
  margin: 5px;
  background-color: var(--color-item-container);
  box-shadow: 0 3px 7px 0 var(--color-shadow-light);
  border-radius: 1rem;
  flex: 1;
}

.media_item_info {
  display: inline-block;
  justify-content: space-between;
  margin-left: 1rem;
  min-height: 2rem;
  width: calc(100% - 1rem);
}

.media_item_draggable {
  cursor: grab;
}

.media_item_image_container {
  position: relative;
  height: 9rem;
  min-width: 16rem;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 5px 0px 5px -3px var(--color-shadow-light);
}

.media_item_image_play {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 1s;
  opacity: 0;
}

.media_item_image_play:hover {
  opacity: 1;
}

.media_item_image_play > img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 50px;
}

.media_item_image_img {
  height: 12rem;
  margin: -2rem 0;
}

.media_item_info_container {
  position: relative;
  width: 25rem;
  margin-left: 15px;
  flex: 1;
}

.media_item_info_title {
  min-height: 24px;
  max-width: calc(100% - 3rem);
}

.media_item_info_title > h2 {
  color: rgba(255,255,255,0.85);
  font-size: 8pt;
  margin: 1rem 0px 0.5rem 1rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.media_item_info_icon {
  padding: 5px 5px;
}

.media_item_info_editable:hover {
  background-color: rgba(255 255 255 / 20%);
  border-radius: 0.2rem;
}

.media_item_info_options {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  box-sizing: content-box;
}

/* MUSIC */

.media_item_info_music {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
  float: left;
}

.media_item_info_song_form {
  display: flex;
  margin: 0 5px 0 5px;
}

.media_item_info_song > div {
  display: flex;
  align-items: center;
  font-size: 14pt;
  color: #999;
  white-space: nowrap;
}

.media_item_info_song_fields {
  margin-right: 15px;
}

.media_item_info_song {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.media_item_info_music_artist {
  margin-left: 1.7rem;
  color: var(--color-text-artist) !important;
  font-size: 12pt;
}

.media_item_info_save_form_buttons {
  display: flex;
  justify-content: space-between;
}

.media_item_info_song_save {
  width: calc(50% - 1px);
}

/* CHARACTER */

.media_item_info_character {
  display: flex;
  align-items: center;
  position: relative;
  float: right;
}

.character_panel_container {
  margin-right: 30px;
  display: flex;
}

.character_panel_item {
  position: relative;
  margin: 0 5px;
}

.character_panel_icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 3rem;
  width: 3rem;
  background: #36384a;
  border-radius: 5px;
}

.character_panel_icon_add {
  position: relative;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
}

.character_panel_icon_add_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1rem;
  width: 1rem;
}

.character_panel_name {
  color: #999;
  font-size: 8pt;
  text-align: center;
  margin-top: 3.2rem;
  min-width: 3rem;
}

/* OTHER */

.media_item_info_tags {
  position: relative;
  display: flex;
  align-items: center;
  margin: 5px 0 5px 1rem;
  color: #999;
}

.media_item_edit_character {
  position: relative;
}

.media_item_edit_character_submit {
  position: absolute;
  right: 0.2em;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.media_item_options {
  float: right;
  margin: 15px 15px 0 0;
  box-sizing: content-box;
}

/* TAG */

.media_item_info_tags > span {
  color: #249aff;
  margin: 0 0.2rem;
}

.media_item_tag_form {
  display: flex;
  width: 100%;
}

.media_item_tag_input {
  width: calc(100% - 10rem);
  margin-left: 2px;
}

.media_item_tag_button {
  width: 4rem;
  margin: 5px 2px;
}

@media only screen and (max-width: 600px) {
  .media_item_container {
    flex-direction: column;
    width: 100%;
    flex: unset;
  }

  .media_item_image_container {
    width: 100%;
    height: unset;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .media_item_image_img {
    width: 100%;
    height: unset;
  }

  .media_item_info_container {
    width: 100%;
    margin: 0;
  }

  .media_item_info {
    flex-wrap: wrap;
  }

  .media_item_info_option_icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    margin: auto;
  }

  .media_item_edit_character {
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem 0;
    background-color: var(--color-item-container);
    z-index: 5;
  }
}