.save_cancel_buttons_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.save_cancel_buttons_button {
  width: 49%;
}

@media only screen and (max-width: 600px) {
  .save_cancel_buttons_button {
    height: 2rem;
  }
}