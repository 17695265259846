.option_menu_container {
  position: relative;
}

.option_menu_floater {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row-reverse;
  background-color: var(--color-option-background);
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0 3px 7px 0 var(--color-shadow-light);
}

.option_menu_item_container {
  position: relative;
  padding: 5px 5px 1px 5px;
  cursor: pointer;
}

.option_menu_item_container:hover {
  background-color: #e7e7e7;
  border-radius: 5px;
}

.option_menu_item_container {
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .option_menu_item_container {
    width: 2.5rem;
    height: 2.5rem;
  }
}