.character_search_float_container_item {
  width: 100%;
  position: relative;
}

.character_search_float_container_item_hover {
  background-color: var(--color-input-dropdown-hover);
}

.character_search_float_container_item_more {
  text-align: center;
}

.character_search_float_container_item_icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.7rem;
}

.character_search_float_item_right_text {
  color: var(--color-text-character) !important;
}