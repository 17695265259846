.header_container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 55px;
  width: 100%;
  background-color: rgb(17 17 17);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.header_content {
  width: 100%;
}

.top_icon {
  position: absolute;
  height: 4rem;
  top: 0;
  bottom: 0;
  margin: auto;
}

.header_content_logo {
  display: block;
  width: 50%;
  margin: auto;
}

.header_content_text {
  color: var(--color-text-secondary);
  font-size: 0.8rem;
  text-align: center;
  margin: 2rem 1rem;
}