.menubar_icon_container {
  width: 4rem;
  height: 45px;
  margin: 5px;
  display: inline-block;
  position: relative;
  float: left;
}

.menubar_content_container {
  position: fixed;
  box-sizing: border-box;
  width: 10em;
  left: -10rem;
  top: 39pt;
  background-color: rgb(17,17,17);
  padding: 40pt 0 5px 0;
  height: calc(100% - 39pt);
  box-shadow: 5px 3px 3px 0px rgb(0 0 0 / 25%);
  transition: 0.5s;
}

.menubar_icon_container_shown {
  left: 0;
}

.menubar_content {
  position: relative;
  width: 10em;
  height: calc(100% - 7rem);
  overflow: hidden;
}

.menu_item_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 5px 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.menu_item_container:hover {
  background-color: rgb(68 68 68);
}

.menu_item_button {
  outline: none;
  border: none;
  color: #e8e8e8;
  background: none;
  font-size: 14pt;
}

.menu_item_button img {
  height: 35pt;
  filter: invert(60%);
}

.menubar_footer {
  position: absolute;
  bottom: 1rem;
  width: 100%;
}

.menubar_footer_item {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: 0.9rem;
  width: 100%;
  box-sizing: border-box;
  color: var(--color-text);
}

.menubar_footer_item a {
  text-decoration: none;
  color: var(--color-text);
}

.menubar_version {
  margin-top: 2rem;
  color: var(--color-text-secondary);
}