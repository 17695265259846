.playlist_queue_list_container {
  position: relative;
  width: 100%;
}

.playlist_queue_current {
  margin-top: 1rem;
}

.playlist_queue_next {
  margin-left: 0.5rem;
}

.playlist_queue_list_next {
  margin-left: 0.5rem;
  font-size: 12pt;
  color: #eee;
  display: flex;
  align-items: center;
}