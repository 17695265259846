.input_field_input_container {
  width: 100%;
  position: relative;
  margin: 5px 0;
}

.input_field_icon {
  position: absolute;
  top: 0;
  left: 0.5em;
  bottom: 0;
  height: 15pt;
  width: 15pt;
  margin: auto;
}

.input_field_input_text {
  font-size: var(--font-input-size);
  width: 100%;
  background-color: var(--color-input-search-background);
  color: var(--color-text);
  border: 1px solid var(--color-input-search-border);
  padding: 5px 0 5px 2rem;
  box-sizing: border-box;
  border-radius: 5px;
}

.input_field_input_text_large {
  padding: 5px 40px;
}

.input_field_input_text::placeholder {
  font-size: 12pt;
  vertical-align: middle;
  font-style: italic;
}

.input_field_input_text:focus {
  background-color: var(--color-input-search-background-focus);
  outline: none;
}