.container_content {
  position: relative;
  top: 50px;
  left: 5px;
  min-width: 800px;
  width: calc(100% - 5px);
}

.media_panel_container {
  position: absolute;
  top: 55px;
  width: 100%;
  height: calc(100% - 55px);
}

.media_panel_watch_panel {
  position: fixed;
  top: 55px;
  width: 100%;
  box-sizing: content-box;
  background-color: var(--color-panel);
  z-index: 50;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 10px 0px var(--color-shadow);
}

.media_panel_side_panel {
  position: fixed;
  right: 0;
  height: calc(100vh - 55px);
  background-color: var(--color-panel-content);
  transition: 1s;
}

.media_panel_watch_panel_open {
  height: 385px;
}

.media_panel_main_panel_offset {}

.media_panel_watch_panel_offset {
  top: calc(55px + 385px);
  height: calc(100vh - 55px - 385px);
}

.media_panel_main_panel {
  transition: 1s;
}

.media_panel_side_panel_none {
  top: 55px;
}

.media_panel_main_panel_closed {
  width: calc(100% - 4rem);
}

.media_panel_main_panel_side_open {
  width: calc(60vw - 4rem);
}

.no_transition {
  transition: none !important;
}

@media only screen and (max-width: 1200px) {
  .media_panel_side_panel {
    height: 3rem;
    width: 100%;
    top: unset;
    bottom: 0;
  }

  .media_panel_side_panel_none {
    height: 3rem;
  }

  .media_panel_main_panel_closed {
    width: 100%;
  }

  .media_panel_main_panel_side_open {
    width: unset;
  }

  .media_panel_main_panel_offset {
    height: calc(100% - 385px - 55px - 2rem) !important;
  }

  .media_panel_watch_panel_offset {
    top: unset;
  }
}

@media only screen and (max-width: 600px) {
}