.toggle_button_container {
    font-size: 18px;
    height: 1rem;
    padding: 0.2rem;
    min-width: 50px;
    user-select: none;
}

.toggle_button_label {
    margin-right: 15px;
}

.toggle_button_switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 100%;
    float: right;
}

.toggle_button_switch input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle_button_slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
    border-radius: 25px;
}

.toggle_button_slider:before {
    position: absolute;
    content: "";
    height: 0.8rem;
    width: 0.8rem;
    left: 4px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

.toggle_button_switch input:checked + .toggle_button_slider {
  background-color: #2196F3;
}

.toggle_button_switch input:focus + .toggle_button_slider {
  box-shadow: 0 0 1px #2196F3;
}

.toggle_button_switch input:checked + .toggle_button_slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}