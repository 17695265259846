.user_profile_container {
  display: inline-block;
  position: relative;
  width: 26pt;
  height: 26pt;
  margin: 12px 5px;
  float: right;
}

.user_profile_container > img {
  border-radius: 50%;
  transition: 1s;
}

.user_profile_container > img:hover {
  box-shadow: 0 0 2px 1px rgb(149 149 149);
}

.user_profile_container_icon {
  height: 20pt;
  width: 20pt;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 0;
}

.user_profile_container_user {
  box-shadow: 0 0 2px 1px rgb(94 94 94);
}

.user_profile_container_no_user:hover {
  box-shadow: 0 0 2px 1px rgb(149 149 149);
}

.user_profile_info_container {
  position: absolute;
  top: 50px;
  right: 0.5rem;
  width: 20rem;
  background-color: #000;
  border-radius: 10px;
}

.user_profile_login_container {
  display: flex;
  height: 5rem;
  justify-content: center;
  margin: 0.3rem 0.4rem;
  background-color: #1b1c1e;
  border-radius: 10px;
  align-items: center;
}

.user_profile_settings_container {
  margin: 0.2rem;
  padding: 0.5rem;
  background-color: #1b1c1e;
  padding: 0.5rem 0.5rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_profile_settings_user_container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.user_profile_settings_info_container {
  margin: 0.2rem;
}

.user_profile_settings_container_icon {
  margin: 0.2rem 0.5rem;
}

.user_profile_settings_logout_container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.user_profile_settings_logout_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.user_profile_settings_logout_container:hover {
  background-color: #5a5a5a;
}

.user_settings_content {
  width: 100%;
}

.user_settings_content_field {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.user_settings_container {
  width: 100%;
}

.user_settings_container > h3 {
  margin: 0 0 0.5rem 0;
  text-transform: uppercase;
  font-size: 10pt;
  color: #999;
}