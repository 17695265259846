.media_watch_container {
  height: 385px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.media_watch_content {
}

.media_watch_close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.media_watch_controls {
  position: relative;
  width: 3rem;
  opacity: 0.6;
  transition: 0.5s;
}

.media_watch_controls:hover {
  opacity: 1;
}

.media_watch_controls_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.5rem;
  right: 0.5rem;
  margin: auto;
  width: 2.5rem;
}

@media only screen and (max-width: 600px) {
  .media_watch_container {
    height: calc(385px + 2rem);
    justify-content: center;
  }

  .media_watch_controls {
    position: absolute;
    bottom: 0;
    height: 3rem;
  }

  .media_watch_controls_left {
    left: 0;
  }

  .media_watch_controls_right {
    right: 0;
  }

  .media_watch_controls_icon {

  }
}