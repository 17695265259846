.playlist_media_list_container {
  margin: 5px;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.playlist_media_list_container::-webkit-scrollbar {
  display: none;
}

.playlist_media_list_empty {
  position: relative;
  height: 100%;
  width: 100%;
}

.playlist_media_list_empty_content {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 1rem;
  text-align: center;
  margin: auto;
  color: var(--color-text-secondary);
}

.playlist_media_dragged {
  opacity: 0.2;
}