html {
  --color-text: #fff;
  --color-text-secondary: #999;
  --color-text-artist: #919dc7;
  --color-text-character: #afbcff;
  --color-background: #0f0f0f;
  --color-panel: #111111;
  --color-panel-side: #000;
  --color-panel-content: #1a1a1a;
  --color-panel-content-secondary: #262626;
  --color-shadow: #000;
  --color-shadow-light: #060606;
  --color-shadow-lighter: #141414;
  --color-shadow-glow: #fff;
  --color-input-search-background: #0f0f0f;
  --color-input-search-border: #303030;
  --color-input-search-border-focus: #838383;
  --color-input-search-background-focus: #222222;
  --color-input-dropdown-hover: #656565;
  --color-input-dropdown-separator: #936466;
  --color-item-container: #101010;
  --color-active-selected: #18285a;
  --color-active-hovered: #263050;
  --color-option-background: #1e1e1e;
  --color-toast-background: #ebebeb;
  --color-toast-text: #000;

  --font-search-size: 12pt;
  --font-input-size: 12pt;

  --min-width: 600px;
}

body {
  font-family: rubik,helvetica,arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 8px 0;
  background-color: var(--color-background);
}