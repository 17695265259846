.media_list_container {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-bottom: 0.1rem;
}

.media_list_empty {
  height: 100%;
  width: 100%;
}

.media_list_empty_content {
  position: absolute;
  top: 55px;
  bottom: 0;
  width: 100%;
  height: 1rem;
  text-align: center;
  margin: auto;
  color: var(--color-text-secondary);
}

.scroll_more_text {
  margin-top: 1rem;
  text-align: center;
}

.scroll_more_text > img {
  min-height: 2rem;
  min-width: 2rem;
}

@media only screen and (max-width: 1200px) {
  .media_list_container {
    padding-bottom: 3.1rem;
  }
}

@media only screen and (max-width: 600px) {
  .media_list_container {
    flex-direction: column;
  }
}