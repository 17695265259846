.playlist_panel_container {
  position: relative;
  height: 100%;
  width: 100%;
}

.playlist_panel_list_container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 2rem;
  width: calc(100% - 2rem);
  height: calc(100% - 3rem);
  background-color: var(--color-panel-content-secondary);
  box-shadow: -3px 0px 10px 0px var(--color-shadow-lighter);
  transition: 1s;
}

.playlist_panel_list_container_closed {
  left: calc(40vw - 7.5rem) !important;
}

@media only screen and (max-width: 1200px) {
  .playlist_panel_list_container_closed {
    left: calc(100%  - 7.5rem) !important;
  }
}