.playlist_menu_container {
  width: calc(100% - 8rem);
  color: var(--color-text);
}

.playlist_menu_header {
  display: flex;
  margin-left: 3rem;
  color: var(--color-text);
}

.playlist_menu_content {
  width: 100%;
}

.playlist_menu_item_container {
  position: relative;
  color: var(--color-text-secondary);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.2rem 0;
  margin: 0 0 0 5px;
  border-bottom: solid #000 1px;
}

.playlist_menu_item_container > img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.2rem;
  margin: auto;
  height: 1rem;
  width: 1rem;
}

.playlist_menu_item_container_title {
  display: inline-block;
  max-width: calc(100% - 4rem);
  padding-left: 2rem;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playlist_menu_item_container:hover {
  background-color: var(--color-active-hovered);
}

.playlist_menu_item_selected {
  background-color: var(--color-active-selected);
}

.playlist_select_container {
  color: var(--color-text);
}

.playlist_menu_item_option_container {
  float: right;
  padding-left: 0 !important;
  margin: 0.1rem;
}

.playlist_menu_item_edit {
  width: calc(100% - 2rem);
  display: flex;
  margin: 0 0 0 2rem;
}

.playlist_menu_item_edit_input {
  
}

.playlist_menu_item_edit_button {
  width: 3rem;
  height: 1.8rem;
  margin: 0.3rem 2px;
}