.searchbar_top_container {
  display: inline-block;
  position: relative;
  height: 100%;
  width: calc(100% - 4rem - 26pt - 25px);
  margin-right: 5px;
}

.searchbar_floater {
  position: absolute;
  width: 100%;
  max-height: 21rem;
  top: 28pt;
  background-color: var(--color-input-search-background-focus);
  border-radius: 5px;
}

.searchbar_dropdown {
  overflow-x: hidden;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: 20rem;
  margin: 0.5rem 0.5rem;
  color: var(--color-text);
}

.searchbar_selected_items {
  width: calc(100% - 2.5rem);
}

.search_dropdown::-webkit-scrollbar {
  display: none;
}

.searchbar_input {
  position: absolute;
}

.song_search_float_item {
  width: 100%;
  position: relative;
}

.song_search_float_item_hover {
  background-color: var(--color-input-dropdown-hover);
}

.song_search_float_item_icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.7rem;
}

.search_token_icon {
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.search_token_song_title {
  font-size: 0.5rem;
}

.search_token_song_artist {
  font-size: 0.5rem;
  color: var(--color-text-artist);
}

.song_search_float_item_right_text {
  height: 1rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  float: right;
  color: var(--color-text-artist);
}

.search_token {
  display: flex;
}

.searchbar_input_prompt {
  font-size: 0.8rem;
}

.searchbar_input_prompt_key {
  font-size: 0.6rem;
  padding: 0 0.2rem;
  border-radius: 3px;
  border: 1px solid var(--color-text-secondary);
}

@media only screen and (max-width: 600px) {
  .searchbar_input_unfocused {
    border: unset;
  }

  .searchbar_input > input {
    width: 0;
  }

  .song_search_float_item {
    font-size: 1.4rem;
  }

  .song_search_float_item_right_text {
    margin-top: 0.2rem;
    font-size: 1rem;
  }

  .searchbar_floater {
    position: fixed;
    left: 2px;
    top: 55px;
    width: calc(100% - 4px);
    max-height: calc(100% - 55px);
  }

  .searchbar_dropdown {
    height: 100%;
    max-height: unset;
  }

  .searchbar_input_prompt {
    display: none;
  }
}