.compact_media_item_container {
  position: relative;
  display: flex;
  margin: 5px;
  background-color: rgba(17,17,17,0.75);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.compact_media_item_image_container {
  position: relative;
}

.compact_media_item_image_play {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 1s;
  opacity: 0;
}

.compact_media_item_image_play:hover {
  opacity: 1;
}

.compact_media_item_image_play > img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 50px;
}

.compact_media_item_image_img {
  height: 5rem;
}

.compact_media_item_info_container {
  width: calc(100% - 110px - 2.6rem);
}

.compact_media_item_info_title {
  position: relative;
  margin: 1rem 5px 1rem 10px;
  box-sizing: content-box;
}

.compact_media_item_info_title > h2 {
  color: rgba(255,255,255,0.85);
  font-size: 10pt;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 1px;
}

.compact_media_item_info_song_container {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.compact_media_item_info_artist {
  color: #7190ff;
  margin-right: 0.5rem;
}

.compact_media_item_info_song {
  color: #aaa;
}

.compact_media_item_info {
  display: flex;
  justify-content: space-between;
}

.compact_media_item_song_icon {
  margin: 0 5px -3px 0;
}

.compact_media_remove_content {
  position: absolute;
  right: 1.5rem;
  width: 1rem;
  height: 100%;
  margin: auto;
}

.compact_media_drag_content {
  position: absolute;
  right: 0;
  height: 100%;
  margin: auto;
  width: 1rem;
  cursor: grab;
}

.compact_media_option_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .compact_media_drag_content {
    display: none;
  }
}