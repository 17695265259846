.token_input_container {
  width: 100%;
  display: flex;
  background-color: var(--color-input-search-background);
  color: var(--color-text);
  border: 1px solid var(--color-input-search-border);
  box-sizing: border-box;
  border-radius: 5px;
}

.token_input_container_focused {
  border: 1px solid var(--color-input-search-border-focus);
}

.token_input_icon {
  position: absolute;
  top: 0;
  left: 0.5em;
  bottom: 0;
  height: 15pt;
  width: 15pt;
  margin: auto;
}

.token_input_token_container {
  position: relative;
  display: flex;
  margin: auto 0.5rem auto 2.5rem;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 4px 1px 5px 2px var(--color-shadow);
}

.token_input_token_container::-webkit-scrollbar {
  display: none;
}

.token_input_token_container > div {
  border: 1px solid var(--color-input-search-border-focus);
  border-radius: 5px;
  padding: 1px 5px;
  margin: 0 1px;
  white-space: nowrap;
}

.token_input_field {
  min-width: 7rem;
  border: none;
  background: unset;
  font-size: var(--font-input-size);
  color: var(--color-text);
  padding: 5px 0 5px 0;
  box-sizing: border-box;
  outline: none;
  flex: 1;
}

.token_input_prompt {
  position: absolute;
  right: 0.5rem;
  top: 0.3rem;
  bottom: 0;
  opacity: 0.5;
  user-select: none;
}

@media only screen and (max-width: 600px) {
  .token_input_field {
    min-width: 3.5rem;
  }
}