.admin_console_header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  color: var(--color-text);
  justify-content: space-between;
  background-color: var(--color-panel);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.admin_console_title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25%;
  width: calc(100% - 6rem - 26pt);
  margin: auto;
  text-align: center;
}

.admin_console_content {
  position: absolute;
  top: 55px;
  width: 100%;
  height: calc(100% - 55px);
}

.admin_console_content_offset {
  left: 10rem;
  width: calc(100% - 10rem);
}

.admin_console_console {
  margin: 1rem;
}

.admin_console_console_list {
  width: 95%;
  margin: auto;
  color: var(--color-text);
}

.admin_console_user_console_item_header {
  color: var(--color-text-secondary);
  margin-bottom: 0.5rem;
}

.admin_console_console_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin_console_console_submit_button {
  height: 1.5rem;
  width: 2rem;
  margin-left: 0.5rem;
}

.admin_console_user_console_item_col {
  font-size: 0.5rem;
  width: 5rem;
  min-width: 5rem;
  text-align: center;
  margin: 0.2rem 0;
}

.admin_console_user_console_item_col_first_new {
  width: 15rem;
  max-width: 15rem;
  min-width: 15rem;
  box-sizing: border-box;
}

.admin_console_user_console_item_col_first {
  width: 15rem;
  min-width: 15rem;
  max-width: 15rem;
}

.admin_console_user_console_item_save {
  width: 10rem;
}

.admin_console_user_console_item_save_hidden {
  visibility: hidden;
}

.admin_console_console_item_full {
  display: flex;
  align-items: center;
  width: 100%;
}

.admin_console_origin_icon_preview {
  height: 1rem;
}

.admin_console_origin_item_value {
  color: var(--color-text);
  width: 10rem;
}

.admin_console_origin_item_value_long {
  color: var(--color-text);
  width: 40rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
}