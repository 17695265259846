.toasts_container {
  position: fixed;
  height: 0;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 5rem;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.toast_container {
  position: relative;
  background-color: var(--color-toast-background);
  color: var(--color-toast-text);
  padding: 1rem 2rem;
  margin: 0.2rem;
  border-radius: 1rem;
}

.toast_container > h2 {
  margin: 0 0 0.5rem 0;
}

.toast_close_icon {
  position: absolute;
  right: 0;
  top: 0;
}

.toast_header {
  position: relative;
  height: 0.1rem;
  width: calc(100% + 1rem);
  margin-top: -0.5rem;
}

.toast_content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 1rem;
  margin-right: 1rem;
}

.toast_icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}