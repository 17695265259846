.playlist_menu_add_container {
  position: relative;
  height: 2em;
  margin: 0 0 0 5px;
}

.playlist_menu_add_container > img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.2rem;
  margin: auto;
}

.playlist_menu_add_container_expanded {
  height: 3em;
}