.icon_color_black {

}

.icon_color_light_grey {
  filter: invert(35%);
}

.icon_color_grey {
	filter: invert(50%);
}

.icon_color_greyer {
	filter: invert(60%);
}

.icon_color_white {
  filter: invert(100%);
}

.icon_color_red {
  filter: invert(50%) sepia(1) hue-rotate(310deg) saturate(3);
}
.icon_color_red:hover {
  filter: invert(50%) sepia(1) hue-rotate(310deg) saturate(5);
}

.icon_color_green {
  filter: invert(50%) sepia(1) hue-rotate(90deg) saturate(3);
}
.icon_color_green:hover {
  filter: invert(50%) sepia(1) hue-rotate(90deg) saturate(5);
}

.icon_color_yellow {
  filter: invert(50%) sepia(1) hue-rotate(15deg) saturate(3);
}
.icon_color_yellow:hover {
  filter: invert(50%) sepia(1) hue-rotate(15deg) saturate(5);
}

.icon_color_blue {
  filter: invert(50%) sepia(1) hue-rotate(180deg) saturate(3);
}
.icon_color_blue:hover {
  filter: invert(50%) sepia(1) hue-rotate(15deg) saturate(5);
}

.icon_color_azure {
  filter: invert(50%) sepia(1) hue-rotate(145deg) saturate(3);
}
.icon_color_azure:hover {
  filter: invert(50%) sepia(1) hue-rotate(145deg) saturate(5);
}

.icon_color_purple {
  filter: invert(50%) sepia(1) hue-rotate(250deg) saturate(3);
}
.icon_color_purple:hover {
  filter: invert(50%) sepia(1) hue-rotate(250deg) saturate(5);
}

.icon_color_discord_purple {
  filter: invert(50%) sepia(1) hue-rotate(190deg) saturate(3);
}
.icon_color_discord_purple:hover {
  filter: invert(50%) sepia(1) hue-rotate(190deg) saturate(5);
}