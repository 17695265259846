.playlist_header_container {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  color: #eee;
  box-sizing: border-box;
}

.playlist_header_options {
  display: flex;
  align-items: center;
  align-content: center;
}

.playlist_header_icon {
  width: 1rem;
  margin: 0.2rem;
}

.playlist_header_content {
  display: flex;
}

.playlist_header_content_toggle {

}

.playlist_header_content_toggle_icon {
  margin-right: 5rem;
}

.playlist_header_name_input_container {
  width: 100%;
  display: flex;
  margin: 0.2rem 1rem 0 -2rem;
}

.playlist_header_name_input {
  width: 100%;
}

.playlist_header_name_input_submit {
  width: 3rem;
  margin: 5px 2px;
}

@media only screen and (max-width: 600px) {
  .playlist_header_icon {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}