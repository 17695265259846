.sidebar_container {
  display: flex;
  height: 100%;
  background-color: var(--color-panel);
}

.sidebar_items {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--color-panel-content);
  box-shadow: inset -3px 0px 3px 0px var(--color-shadow-lighter);
}

.sidebar_item_container {
  position: relative;
  width: 3rem;
  height: 3rem;
}

.sidebar_item_container_selected {
  background: rgba(0 0 0 / 20%);
  box-shadow: inset 0px 2px 3px 0px rgb(0 0 0 / 25%),
    inset 0 -2px 3px 0px rgb(0 0 0 / 25%);
}

.sidebar_item_icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.sidebar_content {
  height: 100%;
  width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color-panel-content);
  transition: 1s;
}

.sidebar_content_open {
  width: 40vw !important;
}

.sidebar_item_container > img {
  width: 1.2rem;
}

.sidebar_hide {
  position: relative;
  height: calc(100% - 8rem);
}

.sidebar_hide_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2rem;
  margin: auto;
}

.sidebar_hide_icon_vertical {
  display: none;
}

.sidebar_button_hidden {
  opacity: 0;
  transition: 1s;
}

@media only screen and (max-width: 1200px) {
  .sidebar_container {
    display: unset;
    height: unset;
  }

  .sidebar_items {
    padding-top: 0;
    flex-direction: row;
    box-shadow: inset 0px -3px 3px 0px var(--color-shadow-lighter);
  }

  .sidebar_hide_icon_horizontal {
    display: none;
  }

  .sidebar_hide_icon_vertical {
    display: block;
  }

  .sidebar_hide {
    height: 3rem;
    width: 100%;
  }

  .sidebar_content {
    width: 100% !important;
  }
}